import { Toast, ToastContainer } from "react-bootstrap";

export const ToastCongrats = ({ show, onClose }: any) => {
  return (
    <ToastContainer position="bottom-start" className="p-3 position-fixed">
      <Toast className="" onClose={onClose} show={show} delay={20000} autohide>
        <Toast.Body>
          <div className="d-flex flex-row px-2 py-1">
            <div className="fa-lg d-flex">
              <i className="fas fa-circle-check my-auto text-success"></i>
            </div>
            <div className="d-flex flex-column ms-3 w-100">
              <h6 className="mb-0 d-flex text-dark">
                Pre Mint Successful!
              </h6>
              <div className="text-gray">
                Welcome to the Degen Fat Cats Family.<br/>Claim your <a href="https://degencoinflip.com/discord" target="_blank">Discord</a> role <a href="https://degencoinflip.com/discord" target="_blank">here</a>.
              </div>
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};