import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useState } from 'react';
import Swiper, { SwiperOptions } from 'swiper';
import { useInterval } from '../../hooks/useInterval';
import CoinFlipWizardContainer from '../coin-flip-wizard/CoinFlipWizard';

const X_AMOUNT = [0.25, 0.5, 0.75, 1];

export const MySwiper = ({ wallet, onNext, onPrev, activeIndex, state, setState }: any) => {

    return (
        <section className="py-5 input-x w-100 bg-secondary" id="try">
            <div className="mb-5">

                <div className="position-relative mb-5">
                    <div className="container my-5">
                        <h2 className="h1 text-center pb-2 pt-5 text-white">Try DCF Now</h2>
                        <div className="position-relative mx-5">

                            {
                                wallet.connected &&
                                <>
                                    {/* {
                                        activeIndex !== 0 &&
                                        <button type="button" id="prev-screen" className="btn btn-light btn-icon rounded-circle position-absolute top-50 start-0 ms-n5 translate-middle-y" tabIndex={0} aria-label="Previous slide" aria-controls="swiper-wrapper-13e84dc53c7d92e5" onClick={onPrev}>
                                            <i className="bx bx-chevron-left"></i>
                                        </button>
                                    } */}
                                    {/* {
                                        activeIndex !== 3 &&
                                        <button type="button" id="next-screen" className="btn btn-light btn-icon rounded-circle position-absolute top-50 end-0 me-n5 translate-middle-y" tabIndex={0} aria-label="Next slide" aria-controls="swiper-wrapper-13e84dc53c7d92e5" onClick={onNext}>
                                            <i className="bx bx-chevron-right"></i>
                                        </button>
                                    } */}
                                </>
                            }
                            <div className="position-absolute top-0 start-50 translate-middle-x h-100 w-100 w-md-33 zindex-5">
                                <div className="d-flex bg-repeat-0 bg-size-cover w-100 h-100 mx-auto" style={{ maxWidth: `328px`, backgroundImage: `url(https://silicon.createx.studio/assets/img/landing/app-showcase/screens/phone-frame.png)` }}></div>
                            </div>
                            <div className="position-absolute top-0 start-50 translate-middle-x h-100 w-100 w-md-33">
                                <div className="d-flex bg-repeat-0 bg-size-cover w-100 h-100 mx-auto" style={{ maxWidth: `328px`, backgroundImage: `url(https://silicon.createx.studio/assets/img/landing/app-showcase/screens/phone-screen.png)` }}></div>
                            </div>
                            <div className="swiper how-it-works-swiper mobile-app-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                <div className="swiper-wrapper" id="swiper-wrapper-13e84dc53c7d92e5" aria-live="polite">

                                    <div className="swiper-slide swiper-slide-active" data-swiper-tab="#text-1" data-swiper-slide-index="0" role="group" aria-label="1 / 4">
                                        <img src="https://i.imgur.com/WzbCZYm.png" className="d-block mx-auto" width="328" alt="Screen" />
                                    </div>

                                    <div className={"swiper-slide swiper-slide-next" + (wallet.connected ? "d-block mx-auto" : "d-none")} data-swiper-tab="#text-2" data-swiper-slide-index="1" role="group" aria-label="2 / 4">
                                        <img className={wallet.connected ? "d-block mx-auto" : "d-none"} src="https://i.imgur.com/KUfBvQF.png" width="328" alt="Screen" />
                                    </div>

                                    <div className="swiper-slide" data-swiper-tab="#text-3" data-swiper-slide-index="2" role="group" aria-label="3 / 4">
                                        <img src="https://i.imgur.com/oPOEYpF.png" className="d-block mx-auto" width="328" alt="Screen" />
                                    </div>

                                    <div className="swiper-slide swiper-slide-duplicate-prev" data-swiper-tab="#text-4" data-swiper-slide-index="3" role="group" aria-label="4 / 4">
                                        <img src={(state?.coinFlip?.won ? "https://i.imgur.com/Xw2H9fr.png" : "https://i.imgur.com/pjb0Y9R.png")} className="d-block mx-auto" width="328" alt="Screen" />
                                    </div>

                                </div>
                                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                            </div>
                        </div>

                        <div className="row justify-content-center pt-4 mt-2 mt-md-3 text-light">
                            <div className="swiper-tabs col-xl-6 col-lg-7 col-md-8 text-center">
                                <div id="text-1" className={"swiper-tab " + (activeIndex === 0 ? " active" : "")}>
                                    <h3 className="h4 pb-1 mb-2 text-white">Step 1. Connect Wallet</h3>
                                    {/* <p className="mb-0">Consequat ut cras nisl, enim purus in facilisi. Ipsum amet, lectus malesuada risus sollicitudin accumsan. Id sem elit vel vel lectus risus senectus.</p> */}
                                </div>

                                <div id="text-2" className={"swiper-tab " + (activeIndex === 1 ? " active" : "")}>
                                    <h3 className="h4 pb-1 mb-2 text-white">Step 2. Flip a Coin</h3>
                                    {/* <p className="mb-0">Enim, et amet praesent pharetra. Mi non ante hendrerit amet sed. Arcu sociis tristique quisque hac in consectetur condimentum.</p> */}
                                </div>

                                <div id="text-3" className={"swiper-tab " + (activeIndex === 2 ? " active" : "")}>
                                    <h3 className="h4 pb-1 mb-2 text-white">Step 3. Approve Flip</h3>
                                    {/* <p className="mb-0">Proin volutpat mollis egestas. Nam luctus facilisis ultrices. Pellentesque volutpat ligula est. Mattis fermentum, at nec lacus.</p> */}
                                </div>

                                <div id="text-4" className={"swiper-tab " + (activeIndex === 3 ? " active" : "")}>
                                    <h3 className="h4 pb-1 mb-2 text-white">
                                        {state?.coinFlip?.won ? `Step 4. Double your SOL` : `Step 4. Get Fucked`}
                                    </h3>
                                    {/* <p className="mb-0">A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className='mb-5 pb-5'>
                            <CoinFlipWizardContainer
                                state={state}
                                setState={setState}>
                            </CoinFlipWizardContainer>
                        </div>
                    </div>
                    {/* <div id="swiper-progress" className="swiper-pagination swiper-pagination-progressbar swiper-pagination-horizontal" style={{ top: `auto` }}>
                    <span className="swiper-pagination-progressbar-fill" style={{ transform: `translate3d(0px, 0px, 0px) scaleX(${X_AMOUNT[activeIndex]}) scaleY(1)`, transitionDuration: `300ms` }}></span>
                </div> */}
                </div>
            </div>

        </section>
    );
};

export const HowItWorks = () => {
    const wallet = useWallet();
    const [swiper, setSwiper] = useState<Swiper>();
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        const swiperOptions: SwiperOptions =
        {
            slidesPerView: 1,
            centeredSlides: true,
            pagination: {
                el: '#swiper-progress',
                type: 'progressbar'
            },
            navigation: {
                prevEl: '#prev-screen',
                nextEl: '#next-screen'
            },
            breakpoints: {
                '768': { slidesPerView: 3 }
            },
            allowTouchMove: false
        };
        const swiper = new Swiper('.how-it-works-swiper', {
            ...swiperOptions
        });

        setSwiper(swiper);

    }, []);

    useEffect(() => {
        if (swiper) {
            console.log(swiper);
            swiper.on('slideChange', function (data: any) {
                const i = data?.activeIndex;
                setActiveIndex(i);

                if (i === 0) {
                    setState({
                        page: null,
                        currentWinStreak: 0
                    });
                }
                if (i === 1) {
                    setState({
                        page: 'one',
                        currentWinStreak: 0
                    });
                }

            });

        }
    }, [swiper]);


    const [state, setState] = useState<any>({
        page: null,
        currentWinStreak: 0
    });

    useEffect(() => {
        const slides: any = {
            one: 1,
            two: 2,
            three: 3
        };
        swiper?.slideTo(slides[state.page]);
        if (state?.page === 'three' && !state?.won) {
            setTimeout(() => {
                setState({
                    page: null,
                    currentWinStreak: 0
            });
            }, 12000);
        }
    }, [state]);


    const onNext = () => {
        swiper?.slideNext();
    }

    const onPrev = () => {
        swiper?.slidePrev();
    }

    return (
        <>
            <MySwiper
                wallet={wallet}
                activeIndex={activeIndex}
                onNext={onNext}
                onPrev={onPrev}
                state={state}
                setState={setState} />
        </>
    )
}