export const FatCatsSection = () => {
  return (
    <section className="container pt-2 pt-sm-4 pb-5 mb-md-2 mb-lg-4 mb-xl-5">
      <div className="row align-items-center mb-2 pb-sm-3 pb-md-4">
        {/* Text */}
        <div className="col-md-5 col-lg-6 col-xl-5 offset-xl-1 order-md-2 text-center text-md-start mb-5 mb-md-0">
          <h2 className="h1 pb-2 pb-lg-3">Degen Fat Cats</h2>
          <p className="pb-2 mb-4 mb-xl-5 fs-xl">Most cats curl up and nap, awaiting an unsuspecting tender night. Some take up the blade to protect their kingdom and nature. Others dabble in coin and entertainment. Few fingers find forgotten tomes for untold magics.<br /><br />
            As for all of us? We're all Fat Cats.<br /><br />
            Degen Fat Cats is an art collection of 20,000 fat cats that benefit from supporting flagship crypto games and tools. Our first contract with the prominent <a href="https://twitter.com/degencoinflip" target="_blank">@degencoinflip</a>.</p>
          {/* <hr />
      <div className="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-md-between pt-3">
        <img src="assets/img/landing/saas-3/feature-3/binance.svg" width={48} className="d-block m-2 mx-md-0" alt="Bitcoin" />
        <img src="assets/img/landing/saas-3/feature-3/ens.svg" width={48} className="d-block m-2 mx-md-0" alt="Ens" />
        <img src="assets/img/landing/saas-3/feature-3/tether.svg" width={48} className="d-block m-2 mx-md-0" alt="Tether" />
        <img src="assets/img/landing/saas-3/feature-3/alqo.svg" width={48} className="d-block m-2 mx-md-0" alt="Alqo" />
        <img src="assets/img/landing/saas-3/feature-3/blockfi.svg" width={48} className="d-block m-2 mx-md-0" alt="BlockFi" />
        <img src="assets/img/landing/saas-3/feature-3/bitcoin.svg" width={48} className="d-block m-2 mx-md-0" alt="Bitcoin" />
      </div> */}
        </div>
        {/* Parallax gfx */}
        <div className="col-md-7 col-lg-6 order-md-1">
          <div className="position-relative d-flex" style={{ maxWidth: '636px' }}>
            <img src="https://i.imgur.com/QaWYGKP.gif" className="d-block rounded-3 mx-auto" alt="Dashboard" style={{ boxShadow: '0 1.875rem 7.5rem -.625rem rgba(124,125,152, .2)' }} />
          </div>
        </div>
      </div>
    </section>

  )
}