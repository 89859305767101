import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { getPhantomWallet, getSlopeWallet, getSolflareWallet, getSolletWallet } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import "./@degen/styles/og.css";

import LayoutContainer from "./layout/layout";
import { PreOrderPage } from "./pages/pre-order/pre-order";
import { MissingRoute } from "./pages/navigate/navigate";
import { Pepe } from "./pages/pepe/pepe";
import { CoinFlips } from "./pages/coin-flips/coin-flips";
import { Gallery } from "./pages/gallery/gallery";

require('@solana/wallet-adapter-react-ui/styles.css');

const wallets = [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getSlopeWallet()];

const App = () => (
    <ConnectionProvider endpoint="http://127.0.0.1:8899">
        <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
                <BrowserRouter>
                    <Routes>
                        <Route element={<LayoutContainer />}>
                            <Route exact path="/" element={<Pepe />} />
                            <Route path="/degencoinflip" element={<CoinFlips />} />
                            <Route path="/gallery" element={<Gallery />} />
                            <Route path="/*" element={<MissingRoute />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </WalletModalProvider>
        </WalletProvider>
    </ConnectionProvider>
);

export default App;