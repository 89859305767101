import { useWallet } from "@solana/wallet-adapter-react";
import { WalletConnectButton, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { getBetaInviteRequest, requestBetaInvite } from "../../api/invites.service";
import CountDownTimer from "../../components/CountDownTimer";
import { Logo } from "../../components/Logo";
import { VideoBg } from "../../components/VideoBg";
import { NavBar } from "../../layout/components/nav-bar";

const MAINTENENCE_TIME = DateTime.fromISO(`2022-11-04T19:00:00.000`, { zone: 'UTC' });
const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const openInNewTab = (url: string = 'https://degencoinflip.com') => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const Pepe = () => {
    const wallet = useWallet();
    const [loading, setLoading] = useState<any>();
    const [alreadyInvited, setAlreadyInvited] = useState<any>();

    useEffect(() => {
        if (
            wallet &&
            wallet.connected &&
            wallet.publicKey
        ) {
            fetchBetaInvite(wallet?.publicKey?.toString());
        }
    }, [wallet]);

    const fetchBetaInvite = async (walletId: any) => {
        setLoading(true);

        try {
            const response = await getBetaInviteRequest(walletId);
            console.log(response);
            if (response) {
                setAlreadyInvited(true);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
        setLoading(false);
    }

    const requestInvite = async (walletId: any) => {
        setLoading(true);
        try {
            const response = await requestBetaInvite(walletId);
            if (response) {
                setAlreadyInvited(true);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
        setLoading(false);
    }

    return (
        <div className={!wallet.connected ? "" : ""}>
            <>``
                <main className="page-wrapper h-100vh">
                    <section className="position-relative h-100vh z-index-5">
                        <div className="container d-flex flex-wrap justify-content-center h-100vh pt-5">
                            <div className="w-100 align-self-end mt-0 pt-1 pt-md-4 pb-4 my-sm-auto mb-auto" style={{ maxWidth: '100%' }}>
                                <div className="w-100 d-flex">
                                    <img className="img-fluid mx-auto cursor-pointer" onClick={() => openInNewTab()} src="https://i.imgur.com/l2LmXlx.png" alt="dcf logo" />
                                </div>
                            </div>
                            <div className="w-100 align-self-end text-center mt-5 mt-sm-3 pt-5 pt-sm-0">
                                <p className="nav d-block text-light fs-sm text-center pb-2 mb-0 mt-5 mt-sm-3">
                                    By&nbsp;
                                    <a className="nav-link d-inline-block p-0 text-light" href="https://twitter.com/NekkroNFT" target="_blank" rel="noopener">@NekkroNFT</a>.
                                </p>
                            </div>
                        </div>

                    </section>
                </main>
            </>
        </div>
    );
}