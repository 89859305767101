import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { act } from "react-dom/test-utils";
import { getActiveSessions } from "../../api/live-api.service";
import { RecentCoinFlipsContext } from "../../contexts/recent-coin-flips.context";
import { SiteStatsContext } from "../../contexts/stats.context";
import { useInterval } from "../../hooks/useInterval";
import { CHALLENGES } from "../../utils/constants";
import { FlipRow } from "../FlipRow";
import { payload } from './flips.data.json';

const removeDups = (coinFlips: any) => {
    const uniqueIds = new Set();
    const unique = coinFlips.filter((coinFlip: any) => {
        const isDuplicate = uniqueIds.has(coinFlip.profileImageUrl);
        uniqueIds.add(coinFlip.profileImageUrl);
        if (!isDuplicate) {
            return true;
        }
        return false;
    });
    return unique;
}


const FlipsList = ({ recentPlayers }: any) => {
    const { recentCoinFlips, fetch } = useContext(RecentCoinFlipsContext);
    const [activeSessions, setActiveSessions] = useState(0);
    useInterval(async () => {
        fetch();
    }, 3000);
    // useEffect(() => {
    //     if (!activeSessions) {
    //         getSessions();
    //     }
    // }, []);
    // const getSessions = async () => {
    //     const sessions = await getActiveSessions();
    //     console.log(sessions);
    //     setActiveSessions(sessions?.length);
    // }
    return (
        <>
            <ul className="list-group bg-light">
                {
                    (recentCoinFlips?.length === 0 ? payload : recentCoinFlips).map((flip: any) => (
                        <FlipRow
                            type=''
                            flip={flip}
                            key={flip.id}
                            defaultProfilePicture={CHALLENGES[0].coinImageUrl}
                            slug=""></FlipRow>))
                }
            </ul>
            <div className="d-flex align-items-center justify-content-center justify-content-lg-start text-start mt-4">
                <div className="d-flex me-3">
                    {
                        (recentCoinFlips?.length === 0 ? removeDups(payload).slice(0, 5) : removeDups(recentCoinFlips).slice(0, 5)).map((flip: any, index: number) => (
                            <div className={"d-flex align-items-center justify-content-center bg-light rounded-circle" + (index ? " ms-n3" : "")}
                                style={{ width: '52px', height: '52px' }}>
                                <img src={flip?.profileImageUrl ?? CHALLENGES[0].coinImageUrl} className="rounded-circle" width={48} alt="Avatar"
                                    onError={(e: any) => {
                                        if (e.target.src !== CHALLENGES[0].coinImageUrl) { e.target.onerror = null; e.target.src = CHALLENGES[0].coinImageUrl; }
                                    }} />
                            </div>
                        ))
                    }
                </div>
                <span className="fs-sm">
                    {
                        !!recentPlayers &&
                        <>
                            <span className="text-dark fw-semibold">{recentPlayers}+</span> degens are currently flipping
                        </>
                    }
                    {
                        !recentPlayers &&
                        <>
                            <span className="text-dark fw-semibold">Degens</span> currently flipping
                        </>
                    }
                </span>
            </div>
        </>
    )
}

export const LiveFlipsWithStats = () => {
    const [counter, setCounter] = useState(1000000);
    const [totalFlips, setTotalFlips] = useState(0);
    const [totalWallets, setTotalWallets] = useState(0);
    const [totalSolFlipped, setTotalSolFlipped] = useState(0);
    const [recentPlayers, setRecentPlayers] = useState(0);
    const { siteStats } = useContext(SiteStatsContext);

    useEffect(() => {
        if (!siteStats) {
            return;
        }
        const {
            totalWallets,
            totalFlips,
            totalSolFlipped,
            recentPlayers
        } = siteStats;
        setTotalFlips(totalFlips);
        setTotalWallets(totalWallets);
        setTotalSolFlipped(totalSolFlipped);
        setRecentPlayers(recentPlayers);
    }, [siteStats])

    useInterval(async () => {
        setTotalFlips(totalFlips + 1);
    }, 6000);

    return (
        <>
            <section className="container pt-4 pb-2 py-md-4 py-lg-5 mb-3 my-md-4 my-lg-5 bg-image-map">
                <div className="row pb-1">
                    <div className="col-md-6 col-xl-5 text-center text-md-start pb-4 mb-2">
                        <h2 className="h1 mb-lg-4">{totalFlips?.toLocaleString()} FLIPS</h2>
                        <p className="pb-4 mb-1 mb-lg-3">Degen Coin Flip is a Solana institution. Since inception, degens have played Double or Nothing, on chain, an incredible {totalFlips?.toLocaleString()} times.</p>
                        <h2 className="h1 mb-lg-4">{totalWallets?.toLocaleString()} USERS</h2>
                        <p className="pb-4 mb-1 mb-lg-3">The number of users that have tried degencoinflip could fill two football stadiums. With our daily active users, DCF is one of the top 10 Dapps on Solana with regularly more users than Orca, Solend, and Mercurial Finance.</p>
                        <h2 className="h1 mb-lg-4">{totalSolFlipped?.toLocaleString()} SOL</h2>
                        <p className="pb-4 mb-1 mb-lg-3">With new users coming to Solana, our volumes have been growing week over week!. We've flipped {totalSolFlipped?.toLocaleString()} sol. That's a lot of coins!</p>
                        <a href="https://mixpanel.com/public/KQMKc744sGRZMq3idvsBmK/" target="_blank" className="btn btn-dark shadow-dark btn-sm">SEE LIVE STATS</a>
                    </div>
                    <div className="col-md-6 offset-xl-1">
                        <FlipsList recentPlayers={recentPlayers} />
                    </div>
                </div>
            </section>
        </>
    )
}