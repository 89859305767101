


import { get, post } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-api.degencoindozer.com/v1';

export const requestBetaInvite = async (walletId: string) => {
  const url = `${BASE_URL}/invites`
  const response = await post(url, { walletId });
  return response?.data?.payload;
}

export const getBetaInviteRequest = async (id: any) => {
  const url = `${BASE_URL}/invites?walletId=${id}`;
  const response = await get(url);
  return response;
}
