// import './theme.css';
import VanillaTilt from 'vanilla-tilt';
import { useContext, useEffect, useRef } from 'react';
import { constants, CHALLENGES } from '../../utils/constants';
import CoinFlipWizardContainer from '../coin-flip-wizard/CoinFlipWizard';
import { RecentCoinFlipsContext } from '../../contexts/recent-coin-flips.context';
import { FlipRow } from '../FlipRow';
import { useInterval } from '../../hooks/useInterval';
import { HowItWorks } from './HowItWorks';
import { HowItWorks2 } from './HowItWorks2';
import { FAQ } from './FAQ';
import { MoreDetails } from './MoreDetails';
import { LiveFeed } from './LiveFeed';
import { Numbers } from './Numbers';
import { Steps } from './Steps';
import { HowItWorksV2 } from './HowItWorksV2';
import { Partners } from './Partners';
import { LiveFlips } from './LiveFlips';
import { HolderUtilities } from './HolderUtilities';
import { WhatPeopleSay } from './WhatPeopleSay';
import { Highlights } from './Highlights';
import { Hero } from './Hero';
import { Features } from './Features';
import { CTA } from './CTA';
import { Footer } from './Footer';
import { Junk } from './Junk';
import { Gallery } from './Gallery';
import { LiveFlipsWithStats } from './LiveFlipsWithStatus';
import { FatCatsSection } from './FatCatsSection';


export const CoinFlipMarketing = () => {

  return (
    <>
      <div className="">

        <Hero />
        {/* <Highlights /> */}
        {/* <Numbers /> */}
        <FatCatsSection />
        <LiveFlipsWithStats />

        <Partners />
        <WhatPeopleSay />
        <HowItWorks />
        {/* <Features /> */}
        <Gallery />
        {/* <LiveFlips /> */}

        {/* <CTA /> */}
        {/* <FAQ /> */}
        {/* <HolderUtilities /> */}

        {/* <Footer /> */}


        {/* <MoreDetails /> */}
        {/* <HowItWorksV2 /> */}
        {/* <Steps /> */}
        {/* <HowItWorks2 /> */}
        {/* <LiveFeed /> */}


        {/* <Junk /> */}
      </ div>
    </>
  )
};