import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isMobile } from 'react-device-detect';
import { AuthorizationContext } from "../../contexts/auth.context";
import { LoadingBars } from "../LoadingBars";

const URL_1 = "https://magiceden.io/marketplace/okay_bears";
const URL_2 = "https://magiceden.io/marketplace/theorcs";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  // console.log(newWindow);
  if (newWindow) newWindow.opener = null
};

export function VideoModal({ styleCss, ...props }) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={styleCss + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg rounded-0">
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/DuB_kbS4THI?controls=0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
}