import { CoinFlipMarketing } from "../../components/coin-flip-marketing/CoinFlipMarketing";
import CoinFlipWizardContainer from "../../components/coin-flip-wizard/CoinFlipWizard";
import { NavBar } from "../../layout/components/nav-bar";
import { constants } from "../../utils/constants";

export const CoinFlips = () => {
    return <>
        <NavBar useDark={true}></NavBar>
        <div className="d-flex h-100 page-wrapper">
            <div className="cover-container d-flex w-100 h-100 mx-auto flex-column h-100vh-desktop">
                <header className="mb-auto" />
                <CoinFlipMarketing></CoinFlipMarketing>
                {/* <div className="container col-xxl-8 px-4 py-5 bg-image-map">
                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div className="col-10 col-sm-8 col-lg-6">
                            <img src={constants.BUTTONS.Processing} className="d-block mx-lg-auto img-fluid" alt="coin flipping" loading="lazy" />
                        </div>
                        <div className="col-lg-6">
                            <h1 className="display-5 fw-bold lh-1 mb-3">DEGEN COIN FLIP</h1>
                            <p className="lead">DCF has over +1m flips since we started and we are the #1 most trusted platform on Solana.</p>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                <img
                                    className="cursor-pointer double-button mb-1"
                                    src={constants.BUTTONS.DoubleOrNothing}
                                    alt="double or nothing"
                                    width="69%"
                                    height="100%" />
                            </div>
                        </div>
                    </div>
                </div> */}
                <footer className="mt-auto" />
            </div>
        </div>
    </>;
};